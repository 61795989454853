<template>
  <body-with-header ads title="دستورالعمل های ثبت شده">
    <CRow>
      <farmuserList @getTips="refreshTips"/>
    </CRow>
    <CRow class="mt-3">
      <tipsList :userId="userIds" />
    </CRow>
  </body-with-header>
</template>

<script>
import farmuserList from "./subForm/farmuserList";
import tipsList from "./subForm/tipsList";
import BodyWithHeader from "../../../components/bodyWithHeader.vue";
export default {
  components: {
    BodyWithHeader,
    farmuserList,
    tipsList,
  },
  data() {
    return {
      userIds: [],
    };
  },
  computed: {},
  methods: {
    refreshTips(ids){
      this.userIds = ids;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style>
</style>
