<template>
  <div class="main-box mt-3">
    <vue-element-loading
      :active="loading"
      :text="loadingtext"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="main-card y-center-g-10" v-if="farmuserSelectedList.length === 0">
      <h6>
        دستورالعمل های ثبت شده توسط تمام کارمندان
      </h6>
      <CButton color="primary" @click="GetFarmuserFilter()">انتخاب کارمند مزرعه</CButton>
    </div>
    <div class="farmuser-list" v-else>
      <horizontal-scroll class="horizontal-scroll">
        <div
          class="farmuser-item"
          v-for="(item, index) of farmuserSelectedList"
          :key="index"
          @click="toggleFilterList(item.id, item.name)"
        >
          <h6>{{ item.name }}</h6>
          <i class="fa fa-window-close" aria-hidden="true"></i>
        </div>
      </horizontal-scroll>
    </div>
    <VueModal
      v-model="modelShowState"
      title="لیست کارمندان شما"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <div class="farmuser-filter-list">
        <div class="filter-item-list" v-if="farmuserSelectedList.length > 0">
          <div
            class="filter-item"
            :class="
              farmuserSelectedList.some((x) => x.id === item.farmuserId)
                ? 'filter-item filter-item-select'
                : 'filter-item'
            "
            v-for="(item, index) of farmuserList"
            :key="index"
            @click="toggleFilterList(item.farmuserId, item.fullName)"
          >
            <i
              :class="
                farmuserSelectedList.some((x) => x.id === item.farmuserId)
                  ? 'fa fa-check-circle-o'
                  : 'fa fa-circle-o'
              "
              aria-hidden="true"
            ></i>
            <h4>
              {{ item.fullName }} ({{ item.phone }}) تعداد زمین :
              {{ item.farmCount }}
            </h4>
          </div>
          <CButton block color="primary" @click="filterList()">نمایش</CButton>
        </div>
        <no-data :show="!loading" v-else message="کارمند مزرعه ای پیدا نشد."/>
      </div>
    </VueModal>
  </div>
</template>

<script>
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import {mapActions} from "vuex";
import NoData from "../../../../components/no-data.vue";

export default {
  components: {
    NoData,
    HorizontalScroll,
  },
  data() {
    return {
      loading: false,
      loadingtext: "",
      modelShowState: false,
      farmuserSelectedList: [],
      farmuserList: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerFarmuser", ["GetAllFarmerFarmuser"]),

    async GetFarmuserFilter() {
      this.loading = true;
      this.loadingtext = "دریافت لیست کارمندان";
      let result = await this.GetAllFarmerFarmuser({
        searchName: "",
      });
      this.loading = false;
      this.modelShowState = true;
      this.farmuserList = result.data.map((item) => {
        return {
          farmuserId: item.farmuserId,
          fullName: item.fullName,
          phone: item.phone,
          farmCount: item.farmCount,
        };
      });
    },
    async toggleFilterList(farmuserId, fullName) {
      if (this.farmuserSelectedList.some((x) => x.id == farmuserId)) {
        const indexOfObject = this.farmuserSelectedList.findIndex((object) => {
          return object.id === farmuserId;
        });
        this.farmuserSelectedList.splice(indexOfObject, 1);
      } else {
        this.farmuserSelectedList.push({
          id: farmuserId,
          name: fullName,
        });
      }
      await this.filterList();
    },

    async filterList() {
      let id = [];
      this.farmuserSelectedList.forEach((element) => {
        id.push(element.id);
      });
      this.$emit("getTips", id);
      this.modelShowState = false;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.main-box {
  width: 100%;
  height: 7vh;
  float: right;
  overflow: hidden;
}
.all-farmusers {
  background-color: white;
  margin: 1em 2em;
  box-shadow: 0 3px 11px -8px #000;
  border-radius: 10px;
}
.all-farmusers h5 {
  width: 100%;
  text-align: center;
  padding: 0.7em 0;
  color: #4f5d73;
}
.horizontal-scroll {
  display: flex;
  width: 100%;
}
.farmuser-list {
  background-color: #d1d1d12e;
  margin: 1em 2em;
  height: 5vh;
  border-radius: 15px;
  border: 1px solid #2eb85c;
  overflow-y: hidden;
  overflow-x: auto;
}
.farmuser-item {
  float: right;
  width: fit-content;
  margin: 0.4em;
  background-color: #00ff55;
  border-radius: 12px;
  color: #4f5d73;
  text-align: center;
  padding: 0.5em 1em 0 1em;
  display: flex;
}
.farmuser-item h6 {
  float: right;
  width: fit-content;
  margin-right: 1em;
  margin-left: 1em;
  color: #000;
}
.farmuser-item i {
  float: right;
  color: #eb9494;
  cursor: pointer;
}
.farmuser-item i:hover {
  color: red;
}

.farmuser-filter-list {
  height: 86vh;
}
.filter-item-list {
  width: 100%;
  height: 86vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-item {
  float: right;
  margin: 0.5em 1.2em;
  background-color: whitesmoke;
  padding: 0.4em;
  box-shadow: -2px 2px 8px -5px #000;
  border-radius: 10px;
  cursor: pointer;
  color: darkgreen;
}
.filter-item:hover {
  background-color: limegreen;
  color: #000;
}
.filter-item-select {
  background-color: limegreen;
  color: #000;
}

.filter-item i {
  float: right;
  margin: 0.4em 0.6em;
  position: relative;
  font-size: 1.2em;
}
.filter-item h4 {
  float: right;
  font-size: 1em;
  margin: 0.4em 1em;
}
</style>
